<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>APPLY FOR JOB</h2>
                    </div>
                </div>
            </div>
        </div>
        <Loader :isLoading="isLoading || processingForm" />

        <div class="vacancies_area">
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="vacancies_left" v-for="dataItem in data" :key="dataItem.id">
                            <h4>JOB OVERVIEW- {{ dataItem.job_title }}</h4>
                            <p>{{ dataItem.overview }}</p>
                            <h5>Experience</h5>
                            <p>{{ dataItem.experience }}</p>
                            <h5>Must Have</h5>
                            <p v-html="dataItem.must_have"></p>
                            <!--<ul>-->
                            <!--  <li>{{dataItem.must_have}}</li> -->
                            <!--</ul>-->
                        </div>
                    </div>

                    <div class="col-md-4" v-if="user_id.created_by != auth_user_id">
                        <div class="vacancies_left vacancies_right">
                            <h3>APPLY FOR JOB</h3>
                            <hr />
                            <form enctype="multipart/form-data">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Your Name"
                                        name="name"
                                        v-model="name"
                                        required
                                    />
                                    <div class="status" id="name-status"></div>
                                </div>

                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Your Email"
                                        name="email"
                                        v-model="email"
                                        required
                                    />
                                    <div class="status" id="email-status"></div>
                                    <div v-if="ress && ress.email" class="text-danger">
                                        {{ ress.email[0] }}
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Your Phone Number"
                                        name="mobile"
                                        v-model="mobile"
                                        required
                                    />
                                    <div class="status" id="mobile-status"></div>
                                </div>

                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Your Education"
                                        name="education"
                                        v-model="education"
                                        required
                                    />
                                    <div class="status" id="education-status"></div>
                                </div>

                                <div class="form-group">
                                    <select
                                        class="form-control"
                                        name="experience"
                                        v-model="experience"
                                        required
                                    >
                                        <option disabled value="">Experience</option>
                                        <option value="fresher">Fresher</option>
                                        <option value="1-5">1-5</option>
                                        <option value="6-10">6-10</option>
                                        <option value=">10">More than >10</option>
                                    </select>
                                    <div class="status" id="experience-status"></div>
                                </div>

                                <div class="form-group">
                                    <select
                                        class="form-control"
                                        name="area"
                                        v-model="area"
                                        required
                                    >
                                        <option disabled value="">Area Expertise</option>
                                        <option
                                            v-for="categoryItem in category"
                                            :key="categoryItem.id"
                                            :value="categoryItem.name"
                                            >{{ categoryItem.name }}</option
                                        >
                                    </select>

                                    <div class="status" id="area-status"></div>
                                </div>

                                <div class="form-group" style="margin-top: 30px;">
                                    <label>Upload Your Resume</label>
                                    <input
                                        type="file"
                                        name="image"
                                        multiple
                                        @change="previewFiles"
                                    />
                                </div>
                                <div class="status" id="resume-status"></div>
                                <div v-if="ress && ress.image" class="text-danger">
                                    {{ ress.image[0] }}
                                </div>
                                <p>upload your resume to view. Max. file size: 1 MB.</p>

                                <div class="form-submit">
                                    <input
                                        type="button"
                                        :value="processingForm ? 'Please wait...' : 'SUBMIT'"
                                        @click="register"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-4" v-else>
                        <p>Sorry! you can not apply for your own Posting</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import scrollTop from "./utils/scrollTop"
import { mapState, mapActions } from "vuex"
import request from "../apis/request"
import Loader from "./Loader.vue"

export default {
    name: "applyforjob",

    data() {
        return {
            isLoading: false,
            category: null,
            data: {},
            id: this.$route.params.id,
            ress: {},
            name: "",
            email: "",
            mobile: "",
            education: "",
            experience: "",
            area: "",
            image: "",
            auth_user_id: "",
            processingForm: false,
            user_id: "",

            action: "/jobregister",
        }
    },
    async mounted() {
        scrollTop()
        this.isLoading = true
        try {
            let res = await request.get("/categories")
            const user = this.user
            this.category = res.data
            if (user) {
                this.auth_user_id = user.id
            }
            res = await request.get("/jobview/" + this.id)
            this.data = res.data

            res = await request.get("/user_job/" + this.id)
            this.user_id = res.data
        } catch (err) {
            console.log("error in loading", err)
        }
        this.isLoading = false
    },
    components: {
        Loader,
    },
    computed: {
        ...mapState("auth", {
            user: "user",
            authenticated: "authenticated",
        }),
    },
    methods: {
        previewFiles(event) {
            console.log(event)
            //console.log(event.target.files[0]);

            this.image = event.target.files[0]
        },

        async register(e) {
            e.preventDefault()

            if (this.name.length == 0) {
                document.getElementById("name-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            }
            if (this.email.length == 0) {
                document.getElementById("email-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            }

            if (this.mobile.length == 0) {
                document.getElementById("mobile-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            }

            if (this.education.length == 0) {
                document.getElementById("education-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            }

            if (this.experience.length == 0) {
                document.getElementById("experience-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            }

            if (this.area.length == 0) {
                document.getElementById("area-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            }

            if (
                !(
                    this.validateEmail() &&
                    this.validatemobile() &&
                    this.name.length > 0 &&
                    this.education.length > 0 &&
                    this.experience.length > 0 &&
                    this.area.length > 0
                )
            )
                return
            this.ress = {}

            if (this.processingForm) return
            this.processingForm = true

            const form = new FormData()
            form.append("id", this.$route.params.id)
            form.append("name", this.name)
            form.append("email", this.email)
            form.append("mobile", this.mobile)
            form.append("education", this.education)
            form.append("experience", this.experience)
            form.append("area", this.area)
            form.append("image", this.image)

            //console.log(this.image);
            try {
                // const res = await request.post(this.action, form)
                console.log(res)
                // window.localStorage.setItem('isLogged', true);
                // window.localStorage.setItem('user', JSON.stringify(res.data));
                //console.log("localstorage", JSON.parse(window.localStorage.getItem('user')));
                this.$toasted.success("Applied for job successfully", {
                    keepOnHover: true,
                    iconPack: "fontawesome",
                    icon: "check",
                    theme: "toasted-primary",
                    // timerProgressBar: true,
                    duration: 3000,
                })

                this.$router.push({ name: "landing" })
                // console.log("ok")
            } catch (res) {
                //alert('err');
                if (res.response.status === 422) {
                    this.ress = res.response.data.errors || {}
                }
                return res
            }
            this.processingForm = false
        },

        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false)
                msg = "<span class='warning' style='color: red;'>Email is not valid yet.</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("email-status").innerHTML = msg
            return res
        },

        validatemobile: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.mobile.length; i++) {
                if (!(this.mobile[i] >= "0" && this.mobile[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false)
                msg = "<span class='warning' style='color: red;'>Oops!!! Digits only.</span>"
            else if (digitCount != 10)
                msg = "<span class='warning' style='color: red;'>No!!! Just 10 digits</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("mobile-status").innerHTML = msg
            return res && digitCount == 10
        },
    },
}
</script>
